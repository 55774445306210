/* eslint-disable @typescript-eslint/no-explicit-any */
import LogRocket from "logrocket";
import React, { useEffect, useContext, createContext } from "react";
import { useStore } from "~/state";

type User = {
  uuid: string;
  name: string;
  email: string;
  access?: string;
  refresh?: string;
  profile?: Profile;
};

type Profile = any;

interface SessionContextProps {
  user?: User;
  profile?: Profile;
  organisations: any[]; // Replace 'any' with the specific type if known
  isAuthenticated: boolean;
  canRefresh: boolean;
  env?: any; // Replace 'any' with the specific type if known
}

const SessionContext = createContext<SessionContextProps>({
  organisations: [],
  isAuthenticated: false,
  canRefresh: false,
});

export const SessionProvider: React.FC<{
  children: React.ReactNode;
  session: { user: User; profile: Profile; organisations: any[] };
}> = ({ children, session }) => {
  const { setProfile, setUser } = useStore();

  const { user, profile, organisations } = session || {};
  const access = user?.access;
  const refresh = user?.refresh;

  // Save in client store
  useEffect(() => {
    setUser({ ...user, profile });
    setProfile(profile);
  }, [user, profile, setUser, setProfile]);

  useEffect(() => {
    LogRocket.identify(user?.uuid, {
      name: user?.name,
      email: user?.email,
      env: import.meta.env.MODE,
    });
  }, [user?.uuid, user?.name, user?.email]);

  const hasRefreshToken = !access && !!refresh;
  const isAuthenticated = !!user && !!access;

  const contextValue: SessionContextProps = {
    user: { ...user, profile },
    profile,
    organisations,
    isAuthenticated,
    canRefresh: !access && !profile && hasRefreshToken,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): SessionContextProps => {
  return useContext(SessionContext);
};
